"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _parseDateTime = _interopRequireDefault(require("./parseDateTime"));
const createTicketText = (intl, startDateTime, rateLines) => intl.formatMessage({
  "id": "PtYchJ",
  "defaultMessage": [{
    "type": 2,
    "style": null,
    "value": "ticketsAmount"
  }, {
    "type": 0,
    "value": " "
  }, {
    "type": 6,
    "pluralType": "cardinal",
    "value": "ticketsAmount",
    "offset": 0,
    "options": {
      "one": {
        "value": [{
          "type": 0,
          "value": "ticket"
        }]
      },
      "other": {
        "value": [{
          "type": 0,
          "value": "tickets"
        }]
      }
    }
  }, {
    "type": 0,
    "value": ", "
  }, {
    "type": 1,
    "value": "date"
  }, {
    "type": 0,
    "value": " om "
  }, {
    "type": 1,
    "value": "time"
  }]
}, {
  date: intl.formatDate((0, _parseDateTime.default)(startDateTime), {
    format: 'DAYMONTHYEAR'
  }),
  time: intl.formatTime((0, _parseDateTime.default)(startDateTime)),
  ticketsAmount: rateLines.reduce((total, line) => total + Number(line.amount ?? 0), 0)
});
var _default = exports.default = createTicketText;