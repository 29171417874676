"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderItemIsTicket = exports.fragment = exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _isBefore = _interopRequireDefault(require("date-fns/isBefore"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _createImgProps = _interopRequireDefault(require("../utils/createImgProps"));
var _createTicketText = _interopRequireDefault(require("../utils/createTicketText"));
var _downloadTORFile = _interopRequireDefault(require("../utils/downloadTORFile"));
var _OrderItem = _interopRequireWildcard(require("./designsystem/OrderItem"));
var _Download = _interopRequireDefault(require("./svg/Download.svg"));
const _excluded = ["data"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment OrderTicket on Ticket {
        id
        downloadVoucherUrl
        ticketStatus: status
    }
`;
const orderItemIsTicket = item => item.__typename === 'Booking';
exports.orderItemIsTicket = orderItemIsTicket;
const getDisclaimerText = (status, voucher) => {
  switch (status) {
    case 'ACCEPTED':
      {
        if (!voucher) {
          return __jsx(_reactIntl.FormattedMessage, {
            id: "4uJa03",
            defaultMessage: [{
              "type": 0,
              "value": "Je kunt je ticket downloaden zodra het restant is betaald"
            }]
          });
        }
        return __jsx(_reactIntl.FormattedMessage, {
          id: "i0AWnO",
          defaultMessage: [{
            "type": 0,
            "value": "Je tickets zijn nu beschikbaar"
          }]
        });
      }
    case 'WAIVED':
      return __jsx(_reactIntl.FormattedMessage, {
        id: "y03QV9",
        defaultMessage: [{
          "type": 0,
          "value": "Ticket vervallen"
        }]
      });
    default:
      return null;
  }
};
const OrderTicket = _ref => {
  let {
      data
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const intl = (0, _reactIntl.useIntl)();
  const {
    activity,
    timeslot,
    rateLines,
    ticketStatus,
    downloadVoucherUrl
  } = data;
  if (!activity || !timeslot) {
    return null;
  }
  const {
    name,
    place
  } = activity;
  const disclaimerText = getDisclaimerText(ticketStatus, downloadVoucherUrl);
  const ticketIsDone = (0, _isBefore.default)(new Date(timeslot.endDateTime), new Date());
  return __jsx(_OrderItem.default, props, __jsx(_grid.Flex, {
    alignItems: ['left', 'center'],
    flex: 1,
    flexDirection: ['column', null, null, 'row']
  }, activity?.jpegThumbnail && activity.webpThumbnail && __jsx(_OrderItem.OrderItemThumbnail, (0, _createImgProps.default)(activity.jpegThumbnail, activity.webpThumbnail)), __jsx(_grid.Box, {
    my: [0, 4, null, null, null, 0],
    ml: [0, null, 4, null, null, 0],
    mx: 2
  }, __jsx(_OrderItem.OrderItemInfo, null, disclaimerText && __jsx(_OrderItem.OrderItemDisclaimer, null, disclaimerText), __jsx(_OrderItem.OrderItemName, null, name, " - ", place), __jsx(_Body.default, {
    variant: "small"
  }, __jsx("span", {
    style: {
      textTransform: 'capitalize'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "2PjVlv",
    defaultMessage: [{
      "type": 0,
      "value": "activiteit"
    }]
  })), ', ', (0, _createTicketText.default)(intl, timeslot.startDateTime, rateLines))))), !ticketIsDone && downloadVoucherUrl && __jsx(_OrderItem.OrderItemButton, {
    onClick: () => (0, _downloadTORFile.default)(downloadVoucherUrl, name)
  }, __jsx(_Download.default, null), __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
    id: "wbjZNA",
    defaultMessage: [{
      "type": 0,
      "value": "Tickets"
    }]
  }))));
};
var _default = exports.default = OrderTicket;